import request from '@/utils/request'

//评价列表
export function getCommentList(params) {
    return request({
        url: '/admin/comment-list',
        method: 'GET',
        params
    })
}
//评价详情
export function getCommentInfo(params) {
    return request({
        url: '/admin/comment-info/' + params.id,
        method: 'GET',
        params
    })
}
//屏蔽
export function postCommentEdit(data) {
    return request({
        url: '/admin/comment-edit/' + data.id,
        method: 'post',
        data
    })
}
